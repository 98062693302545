// @ts-check
import { createSelector } from 'reselect';
import { tvEpisodeSelector } from '../tv-episode-selector/tv-episode-selector';

export const questionsSelector = createSelector(
  [tvEpisodeSelector],
  (episode) =>
    episode.questions
      .filter((q) => q.question_type !== 'SECTION')
      .sort((q1, q2) => q1.order_number - q2.order_number)
);
