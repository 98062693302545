import initialState from '../elamani-biisi-initial-state';
import { asyncSuffixes, REQUEST_SUFFIX } from '../../constants/action-types';

export default function (state = initialState.loadingState, action) {
  const { type, isLoadingIgnored = false } = action;
  const actionSuffix = asyncSuffixes.find((suffix) => type.includes(suffix));
  if (!actionSuffix || isLoadingIgnored) {
    return state;
  }
  const actionName = type.split(actionSuffix)[0];
  return {
    ...state,
    [actionName]: actionSuffix === REQUEST_SUFFIX,
  };
}
