// @ts-check
import * as React from 'react';
import {
  Text,
  StyleSheet,
  View,
  Platform,
  TouchableOpacity,
} from 'react-native';
import * as PropTypes from 'prop-types';

import LinearGradient from 'react-native-web-linear-gradient';
import * as propTypes from '../../constants/prop-types';
import dimensions from '../../constants/dimensions';
import colors from '../../constants/colors';
import fonts from '../../constants/fonts';
import EbFireworksAnimation from '../EbFireworksAnimation/EbFireworksAnimation';

class EbOptionItem extends React.Component {
  handleOnSelect = () => {
    const { onSelect, option, points } = this.props;
    onSelect(option, points);
  };

  handleOnDeselect = () => {
    const { onDeselect, option } = this.props;
    onDeselect(option);
  };

  render() {
    const {
      option,
      answer,
      highlighted,
      disabled,
      unselectedBackgroundColors,
      disabledBackgroundColors,
      highlightedBackgroundColors,
      selectedBackgroundColors,
      fireworks,
      fireworksDelay,
      compactMode,
    } = this.props;

    let selectedStyle = [styles.selected];
    let containerStyle = [styles.container];

    const selected = answer?.id === option.id;
    let gradientColors = disabled
      ? disabledBackgroundColors
      : unselectedBackgroundColors;

    if (selected) {
      gradientColors = selectedBackgroundColors;
      selectedStyle = [...selectedStyle, highlighted ? styles.highlighted : {}];
      containerStyle = [...containerStyle, selectedStyle];
    }

    if (highlighted) {
      gradientColors = highlightedBackgroundColors;
    }

    return (
      <TouchableOpacity
        onPress={selected ? this.handleOnDeselect : this.handleOnSelect}
        style={styles.clickableContainer}
        accessibilityRole="button"
        disabled={disabled}
      >
        <View
          style={[
            styles.clickableContainer,
            compactMode && styles.compactClickableContainer,
          ]}
        >
          <View style={[styles.wrapperStyle]}>
            <LinearGradient
              style={containerStyle}
              start={{ x: 0, y: 0.0 }}
              end={{ x: 0, y: 0.7 }}
              colors={gradientColors}
            >
              <View
                style={[
                  styles.textContainer,
                  compactMode && styles.compactTextContainer,
                ]}
              >
                <Text style={[styles.text, compactMode && styles.compactText]}>
                  {option.text.trim().toUpperCase()}
                </Text>
              </View>
            </LinearGradient>
            {fireworks && (
              <EbFireworksAnimation
                delay={fireworksDelay}
                mode={EbFireworksAnimation.mode.MEDIUM}
              />
            )}
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

EbOptionItem.defaultProps = {
  answer: {},
  highlighted: false,
  points: 0,
  selectedBackgroundColors: [colors.white, colors.white],
  unselectedBackgroundColors: [
    colors.whiteTransparent70,
    colors.whiteTransparent70,
  ],
  disabledBackgroundColors: [
    colors.whiteTransparent8,
    colors.whiteTransparent8,
  ],
  highlightedBackgroundColors: [colors.gold, colors.brown],
  fireworks: false,
  fireworksDelay: 0,
  disabled: false,
  compactMode: false,
  onDeselect: () => {},
};

EbOptionItem.propTypes = {
  highlighted: PropTypes.bool,
  points: PropTypes.number,
  option: propTypes.questionOption.isRequired,
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func,
  answer: PropTypes.shape({}),
  selectedBackgroundColors: PropTypes.arrayOf(PropTypes.string),
  unselectedBackgroundColors: PropTypes.arrayOf(PropTypes.string),
  disabledBackgroundColors: PropTypes.arrayOf(PropTypes.string),
  highlightedBackgroundColors: PropTypes.arrayOf(PropTypes.string),
  fireworks: PropTypes.bool,
  fireworksDelay: PropTypes.number,
  compactMode: PropTypes.bool,
  disabled: PropTypes.bool,
};

const styles = StyleSheet.create({
  clickableContainer: {
    paddingTop: 8,
  },
  compactClickableContainer: {
    paddingTop: 0,
  },
  container: {
    marginHorizontal: dimensions.halfMargin,
    borderRadius: dimensions.borderRadius * 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  compactContainer: {
    marginHorizontal: 1,
  },
  wrapperStyle: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    ...fonts.textBold,
    color: colors.textPurple,
    fontSize: 12,
    whiteSpace: 'nowrap',
    letterSpacing: 2,
  },
  compactText: {
    fontSize: 11,
  },
  textContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: dimensions.mediumMargin,
    minHeight: 27,
  },
  compactTextContainer: {
    paddingHorizontal: dimensions.halfGutter,
    minHeight: 20,
  },
  selected: {
    shadowOpacity: 0.8,
    shadowRadius: 20,
    shadowColor: colors.gold,
  },
  highlighted: {
    shadowColor: colors.gold,
  },
});

export default EbOptionItem;
