import initialState from '../elamani-biisi-initial-state';
import actionTypes from '../../constants/action-types';

export default function (state = initialState.tvEpisodeState, action) {
  switch (action.type) {
    case actionTypes.GET_TV_EPISODE_SUCCESS:
      return {
        ...state,
        episode: action.episode,
      };
    case actionTypes.EB_GET_EPISODE_SUCCESS:
      return {
        ...state,
        episode: action.episode,
      };
    case actionTypes.GET_TV_EPISODE_SCORE_STATS_SUCCESS:
      return {
        ...state,
        scoreStats: action.stats,
      };
    default:
      return state;
  }
}
