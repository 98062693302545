import { createSelector } from 'reselect';

const yleTunnusSelector = (state) => state.ebGeneralState.yleTunnus;

export const isLoggedInSelector = createSelector(
  yleTunnusSelector,
  ({ loggedIn }) => loggedIn
);

export const nickSelector = createSelector(
  yleTunnusSelector,
  ({ user }) => user?.nick
);
