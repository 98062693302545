/* istanbul ignore file */

import { createSelector } from 'reselect';
import { pick, head, compact } from 'lodash';
import { convertStartTimeToMillis } from '../../util/times';

export const tvEpisodeStateSelector = (state) => state.tvEpisodeState;
export const episodeReviewsSelector = (state) =>
  state.tvEpisodeState.episode.reviews;
export const episodeScoreStatsSelector = (state) =>
  state.tvEpisodeState.scoreStats;

export const questionSelector = (questions) =>
  compact(questions)
    .map((x) => {
      const question = pick(x, [
        'uuid',
        'description',
        'main_text',
        'max_score',
        'settings',
        'tags',
        'child_ids',
        'question_type',
        'order_number',
        'options',
        'content',
      ]);
      return question.settings.timestamps
        ? {
            ...question,
            settings: {
              ...question.settings,
              timestamps: head(question.settings.timestamps),
            },
          }
        : question;
    })
    .sort(
      (q1, q2) =>
        convertStartTimeToMillis(q1.settings.timestamps) -
        convertStartTimeToMillis(q2.settings.timestamps)
    );

export const tvEpisodeSelector = createSelector(
  tvEpisodeStateSelector,
  (tvEpisodeState) => {
    const episode = pick(tvEpisodeState.episode, [
      'uuid',
      'name',
      'description',
      'language',
      'questions',
      'series_uuids',
      'classification',
    ]);
    return {
      ...episode,
      questions: questionSelector(episode.questions),
    };
  }
);

export const maxScoreSelector = createSelector(tvEpisodeSelector, (episode) =>
  episode.questions.reduce((total, question) => {
    /* eslint-disable */
    const { question_type, settings } = question;
    if (question_type !== 'SECTION' && settings.points) {
      return total + Number(settings.points);
    }
    return total;
    /* eslint-enable */
  }, 0)
);

export const allSectionsSelector = createSelector(
  tvEpisodeSelector,
  (episode) =>
    episode.questions
      .filter((question) => question.question_type === 'SECTION')
      .sort(
        (q1, q2) =>
          convertStartTimeToMillis(q1.settings.timestamps) -
          convertStartTimeToMillis(q2.settings.timestamps)
      )
);

export const selectedEpisodeUuidSelector = createSelector(
  tvEpisodeSelector,
  ({ uuid }) => uuid
);

export const endTimeOfSelectedEpisodeSelector = createSelector(
  tvEpisodeSelector,
  // eslint-disable-next-line camelcase
  (episode) => episode?.classification?.end_time
);

export const revealTimeOfSelectedEpisodeSelector = createSelector(
  tvEpisodeSelector,
  // eslint-disable-next-line camelcase
  (episode) => episode?.classification?.reveal_time
);

export const startTimeOfSelectedEpisodeSelector = createSelector(
  tvEpisodeSelector,
  (episode) => episode?.classification?.start_time
);
