import { put, call, takeLatest } from 'redux-saga/effects';

import {
  fetchTvEpisode,
  fetchTvEpisodeScores,
} from '../../api/tehtava-api/tehtava-api';
import actionTypes from '../../constants/action-types';
import {
  getTvEpisodeSuccessAction,
  getTvEpisodeErrorAction,
  getTvEpisodeScoreStatsSuccessAction,
} from '../../actions/tv-episode-actions';

export function* watchGetTvEpisode() {
  yield takeLatest(actionTypes.GET_TV_EPISODE_REQUEST, getTvEpisodeSaga);
}

export function* watchGetTvEpisodeScoreStats() {
  yield takeLatest(
    actionTypes.GET_TV_EPISODE_SCORE_STATS_REQUEST,
    getTvEpisodeScoreStatsSaga
  );
}

function* getTvEpisodeSaga(action) {
  try {
    const episode = yield call(fetchTvEpisode, action.episodeId);

    yield put(getTvEpisodeSuccessAction({ episode }));
  } catch (error) {
    yield put(getTvEpisodeErrorAction({ error }));
  }
}

function* getTvEpisodeScoreStatsSaga(action) {
  try {
    const stats = yield call(fetchTvEpisodeScores, action.episodeId);

    yield put(getTvEpisodeScoreStatsSuccessAction({ stats }));
  } catch (error) {
    yield put(getTvEpisodeErrorAction({ error }));
  }
}
