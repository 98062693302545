import * as React from 'react';
import { Component } from 'react';
import * as _ from 'lodash';
import {
  View,
  Image,
  TextInput,
  StyleSheet,
  TouchableOpacity,
} from 'react-native';
import * as PropTypes from 'prop-types';

import dimensions from '../../constants/dimensions';
import fonts from '../../constants/fonts';
import colors from '../../constants/colors';
import ClearTextButton from '../../assets/clear_text_button.webp';

class EbfTextInput extends Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
  }

  handleOnClearText = () => {
    const { onChangeText, name } = this.props;
    onChangeText(name, '');
  };

  handleOnChangeText = (newValue) => {
    const { onChangeText, name } = this.props;
    onChangeText(name, newValue);
  };

  handleOnSubmitEditing = () => {
    const { onSubmit } = this.props;
    onSubmit();
  };

  render() {
    const { maxLength, value, autoFocus, placeholder } = this.props;
    const clearTextButtonStyle = [
      styles.clearTextButton,
      _.isEmpty(value) ? { display: 'none' } : { display: 'flex' },
    ];
    return (
      <View style={styles.container}>
        <TextInput
          ref={this.textInput}
          value={value}
          autoFocus={autoFocus}
          maxLength={maxLength}
          selectionColor={colors.gold}
          placeholderTextColor={colors.whiteTransparent50}
          style={styles.textInput}
          numberOfLines={1}
          multiline={false}
          onChangeText={this.handleOnChangeText}
          placeholder={placeholder}
          keyboardType="default"
          underlineColorAndroid={colors.transparent}
          keyboardAppearance="dark"
          clearButtonMode="never"
          autoCapitalize="words"
          returnKeyType="done"
          onSubmitEditing={this.handleOnSubmitEditing}
          autoCorrect={false}
          enablesReturnKeyAutomatically
        />
        <TouchableOpacity
          activeOpacity={0.5}
          style={clearTextButtonStyle}
          onPress={this.handleOnClearText}
        >
          <Image source={ClearTextButton} />
        </TouchableOpacity>
      </View>
    );
  }
}

EbfTextInput.defaultProps = {
  autoFocus: false,
};

EbfTextInput.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  maxLength: PropTypes.number.isRequired,
  onChangeText: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: dimensions.mediumMargin,
    borderBottomColor: colors.white,
    borderBottomWidth: 2,
    marginVertical: 16,
    width: '100%',
  },
  textInput: {
    ...fonts.formTextInput,
    flex: 1,
    color: colors.white,
  },
  clearTextButton: {},
});

export default EbfTextInput;
