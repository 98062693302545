import { put, call, takeLatest, select } from 'redux-saga/effects';

import { fetchSeries } from '../../api/tehtava-api/tehtava-api';
import actionTypes from '../../constants/action-types';
import {
  getSeriesSuccessAction,
  getSeriesErrorAction,
} from '../../actions/series-actions';

export function* watchGetSeries() {
  yield takeLatest(actionTypes.GET_SERIES_REQUEST, getSeriesSaga);
}

function* getSeriesSaga(action) {
  try {
    const series = yield call(fetchSeries, action.seriesId);
    /* istanbul ignore next */
    if (!series) {
      throw new Error('Series not found.');
    }
    yield put(getSeriesSuccessAction({ series }));
  } catch (error) {
    yield put(getSeriesErrorAction({ error }));
  }
}
