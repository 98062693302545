import { asyncSuffixes } from '../../constants/action-types';

const maybeRemoveSuffix = (action) => {
  const actionSuffix = asyncSuffixes.find((suffix) => action.includes(suffix));
  if (!actionSuffix) {
    return action;
  }
  return action.split(actionSuffix)[0];
};

export const loadingSelector = (state, actions) => {
  if (typeof actions === 'string') {
    return !!state.loadingState[maybeRemoveSuffix(actions)];
  }
  return actions.some(
    (action) => state.loadingState[maybeRemoveSuffix(action)]
  );
};
