import initialState from '../elamani-biisi-initial-state';
import actionTypes from '../../constants/action-types';

export default function (state = initialState.seriesState, action) {
  switch (action.type) {
    case actionTypes.GET_SERIES_SUCCESS:
      return {
        ...state,
        series: action.series,
      };
    case actionTypes.GET_TV_EPISODE_REQUEST:
      return {
        ...state,
        selectedEpisode: action.episodeId,
      };
    default:
      return state;
  }
}
