import * as React from 'react';
import { Component } from 'react';
import { Text, ScrollView, StyleSheet } from 'react-native';
import { withRouter } from 'react-router-dom';
import * as PropTypes from 'prop-types';
import * as _ from 'lodash';

import { routePaths } from '../../constants/route-paths';
import EbBackground from '../EbBackground/EbBackground';
import EbfTextInput from '../EbfTextInput/EbfTextInput';
import EbFilledButton from '../EbFilledButton/EbFilledButton';
import dimensions from '../../constants/dimensions';
import fonts from '../../constants/fonts';
import colors from '../../constants/colors';
import EbBackButton from '../common/EbBackButton/EbBackButton';
import EbPopupMessage from '../../components/EbPopupMessage/EbPopupMessage';
import * as strings from '../../constants/ui-strings';
class EbfAddPlayerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playerName: '',
      songArtist: '',
      songName: '',
      formIsInvalid: true,
      scrollviewHeight: 0,
      showDialog: false,
    };
  }

  handleOnTextFieldChangeText = /* istanbul ignore next */ (name, newValue) =>
    this.setState({ [name]: newValue }, () =>
      this.setState({ formIsInvalid: this.isFormInvalid() })
    );

  handleOnTextFieldSubmit = () => {
    const { formIsInvalid } = this.state;
    if (!formIsInvalid) {
      this.handleOnSubmitButtonPressed();
    }
  };

  handleOnSubmitButtonPressed = () => {
    const { onSubmit } = this.props;
    const { playerName, songArtist, songName } = this.state;
    onSubmit(_.trim(playerName), _.trim(songArtist), _.trim(songName));
  };

  isFormInvalid = () => {
    const { playerName, songArtist, songName } = this.state;
    return (
      _.isEmpty(_.trim(playerName)) ||
      _.isEmpty(_.trim(songArtist)) ||
      _.isEmpty(_.trim(songName))
    );
  };

  onGoBack = () => {
    this.setState({ ...this.state, showDialog: false }, () => {
      this.props.history.push(routePaths.ELAMANI_BIISI_EPISODE_LIST);
    });
  };

  handleOpenDialog = () => {
    this.setState({ ...this.state, showDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ ...this.state, showDialog: false });
  };

  handleOnScrollViewLayout = (e) =>
    this.setState({ scrollviewHeight: e.nativeEvent.layout.height });

  render() {
    const {
      playerName,
      songArtist,
      songName,
      formIsInvalid,
      scrollviewHeight,
      showDialog,
    } = this.state;
    const { playerIndex, isLastPlayer } = this.props;
    const titleString = `Pelaaja ${playerIndex + 1}`;
    return (
      <EbBackground style={styles.background}>
        {showDialog && (
          <EbPopupMessage
            title={strings.friendGameEndGame}
            content=""
            secondaryDismissButtonText={strings.yes}
            onClosePress={this.onGoBack}
            dismissButtonText={strings.no}
            onDismissButtonPress={this.handleCloseDialog}
          />
        )}
        <ScrollView
          onLayout={this.handleOnScrollViewLayout}
          style={styles.container}
          contentContainerStyle={[
            styles.scrollContentContainer,
            { minHeight: scrollviewHeight },
          ]}
        >
          <Text style={styles.title}>{titleString}</Text>
          <Text style={styles.subTitle}>Kerro nimesi ja elämäsi biisi</Text>
          <EbfTextInput
            name="playerName"
            value={playerName}
            maxLength={8}
            placeholder="Nimesi"
            onFocus={this.handleOnTextFieldFocus}
            onChangeText={this.handleOnTextFieldChangeText}
            onSubmit={this.handleOnTextFieldSubmit}
          />
          <EbfTextInput
            name="songArtist"
            value={songArtist}
            maxLength={25}
            placeholder="Elämäsi biisin esittäjä"
            onFocus={this.handleOnTextFieldFocus}
            onChangeText={this.handleOnTextFieldChangeText}
            onSubmit={this.handleOnTextFieldSubmit}
          />
          <EbfTextInput
            name="songName"
            value={songName}
            maxLength={25}
            placeholder="Elämäsi biisin nimi"
            onFocus={this.handleOnTextFieldFocus}
            onChangeText={this.handleOnTextFieldChangeText}
            onSubmit={this.handleOnTextFieldSubmit}
          />
          <EbFilledButton
            containerStyle={styles.buttonContainer}
            onPress={this.handleOnSubmitButtonPressed}
            text={isLastPlayer ? 'Jatka' : 'SEURAAVA PELAAJA'}
            disabled={formIsInvalid}
          />
        </ScrollView>
        <EbBackButton onPress={this.handleOpenDialog} />
      </EbBackground>
    );
  }
}

EbfAddPlayerForm.propTypes = {
  playerIndex: PropTypes.number.isRequired,
  isLastPlayer: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: '100%',
  },
  background: {
    alignItems: 'center',
    paddingHorizontal: 0,
  },
  buttonContainer: {
    marginTop: dimensions.gutter,
  },
  scrollContentContainer: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: dimensions.mediumMargin,
    paddingBottom: dimensions.largeMargin,
    margin: dimensions.margin,
    marginBottom: dimensions.gutter,
  },
  title: {
    ...fonts.titleLight,
    color: colors.white,
    marginBottom: dimensions.halfMargin,
  },
  subTitle: {
    ...fonts.h2,
    color: colors.white,
  },
});

export default withRouter(EbfAddPlayerForm);
