import { put, call, takeLatest, take, select } from 'redux-saga/effects';
import { isEmpty, negate } from 'lodash';
import actionTypes from '../../constants/action-types';
import {
  getEbEpisodeSuccessAction,
  getEbEpisodeErrorAction,
  sendEbAnswersErrorAction,
  sendEbAnswersSuccessAction,
  resetEbEpisodeAction,
  ebSetUserSubmittedAnswersAction,
  getEbEpisodeAnswersSuccessAction,
  getEbEpisodeAnswersErrorAction,
  getEbEpisodeAction,
  getLeaderboardParticipationStatusSuccessAction,
  getLeaderboardParticipationStatusErrorAction,
  setLeaderboardParticipationStatusSuccessAction,
  setLeaderboardParticipationStatusErrorAction,
  getLeaderboardRequestAction,
  getLeaderboardSuccessAction,
  getLeaderboardErrorAction,
} from '../../actions/actions';
import {
  fetchTvEpisode,
  postAnswers,
  fetchAnswers,
  fetchLeaderboardParticipationStatus,
  postLeaderboardParticipationStatus,
  fetchLeaderboard,
} from '../../api/tehtava-api/tehtava-api';
import { selectedEpisodeUuidSelector } from '../../selectors/tv-episode-selector/tv-episode-selector';
// import * as routeNames from '../../constants/route-names';
import { ebEpisodeAnswersSelector } from '../../selectors/eb-episode-game-selector/eb-episode-game-selector';
import { nickSelector } from '../../selectors/user-selector/user-selector';
import { questionsSelector } from '../../selectors/questions-selector/questions-selector';
import { serializeAnswers } from '../../util/tehtava';
import {
  addEpisodeQuestionOptionIndexes,
  findUserInLeaderboard,
} from '../../util/helpers';
// import { NavigationService } from '../../../../util/navigation';

// GET EPISODE

export function* watchGetEbEpisode() {
  yield takeLatest(actionTypes.EB_GET_EPISODE_REQUEST, getEbEpisodeSaga);
}

function* getEbEpisodeSaga(action) {
  try {
    if (action.reset) {
      yield put(resetEbEpisodeAction());
    }
    const episode = yield call(fetchTvEpisode, action.episodeId);
    /* istanbul ignore next */
    if (!episode) {
      throw new Error('Episode not found.');
    }
    const episodeWithIndexedOptions = yield call(
      addEpisodeQuestionOptionIndexes,
      episode
    );
    yield put(
      getEbEpisodeSuccessAction({ episode: episodeWithIndexedOptions })
    );
  } catch (error) {
    yield put(getEbEpisodeErrorAction({ error }));
  }
}

// SEND ANSWERS

export function* watchSendEbAnswers() {
  yield takeLatest(actionTypes.EB_SEND_ANSWERS_REQUEST, sendEbAnswersSaga);
}

function* sendEbAnswersSaga() {
  try {
    // Attempt to reload current episode
    yield call(updateSelectedEbEpisodeSaga);
    // Wait reload result
    const reloadResultAction = yield take([
      actionTypes.EB_GET_EPISODE_SUCCESS,
      actionTypes.EB_GET_EPISODE_ERROR,
    ]);
    // Send answers only if episode was successfully reloaded (so that user will be notified if answers are not accepted anymore)
    if (reloadResultAction.type === actionTypes.EB_GET_EPISODE_ERROR) {
      yield put(
        sendEbAnswersErrorAction({
          error: 'Failed to reload episode before submitting answers',
        })
      );
    } else {
      const answers = yield select(ebEpisodeAnswersSelector);
      /* istanbul ignore next */
      const serializedAnswers = serializeAnswers(answers);
      const answersResponse = yield call(postAnswers, serializedAnswers);
      yield put(sendEbAnswersSuccessAction({ answers: answersResponse }));
    }
  } catch (error) {
    yield put(sendEbAnswersErrorAction({ error }));
  }
}

// TODO: Move to utils
const findQuestionById = (question_uuid, questions) =>
  questions.filter(({ uuid }) => uuid === question_uuid)[0];

// TODO: Move to utils
const findQuestionOptionByAnswer = (answer_data, question) => {
  const optionValue = Object.values(answer_data)?.[0]?.toString().trim();
  if (!optionValue) {
    return null;
  }
  return question.options.filter(({ text }) => text.trim() === optionValue)[0];
};

function* setUserSubmittedAnswersSaga(action) {
  const { answers } = action;
  const questions = yield select((state) => questionsSelector(state));
  const answerOptions = answers
    .map(({ question_uuid, answer_data, score }) => ({
      ...findQuestionOptionByAnswer(
        answer_data,
        findQuestionById(question_uuid, questions)
      ),
      score,
    }))
    .filter(negate(isEmpty));

  if (answerOptions.length && answerOptions.length === questions.length) {
    yield put(ebSetUserSubmittedAnswersAction({ answers: answerOptions }));
  }
}

// FETCH ANSWERS

export function* watchGetEbEpisodeAnswers() {
  yield takeLatest(
    actionTypes.EB_GET_EPISODE_ANSWERS_REQUEST,
    getEbEpisodeAnswersSaga
  );
}

function* getEbEpisodeAnswersSaga(action) {
  const { episodeId } = action;
  try {
    const answers = yield call(fetchAnswers, episodeId);
    if (!answers) {
      throw new Error('Episode not found.');
    }
    yield put(getEbEpisodeAnswersSuccessAction({ answers }));
  } catch (error) {
    yield put(getEbEpisodeAnswersErrorAction({ error }));
  }
}

export function* watchGetEbEpisodeAnswersSaga() {
  yield takeLatest(
    actionTypes.EB_GET_EPISODE_ANSWERS_SUCCESS,
    setUserSubmittedAnswersSaga
  );
}

// UPDATE SELECTED EPISODE

export function* watchUpdateSelectedEbEpisode() {
  yield takeLatest(
    actionTypes.EB_UPDATE_SELECTED_EPISODE,
    updateSelectedEbEpisodeSaga
  );
}

function* updateSelectedEbEpisodeSaga() {
  const episodeId = yield select(selectedEpisodeUuidSelector);
  yield put(getEbEpisodeAction({ episodeId, reset: false }));
}

// LEADERBOARD

export function* watchGetLeaderboardParticipationStatusRequestAction() {
  yield takeLatest(
    actionTypes.EB_GET_LEADERBOARD_PARTICIPATION_STATUS_REQUEST,
    getLeaderboardParticipationStatusSaga
  );
}

function* getLeaderboardParticipationStatusSaga(action) {
  try {
    const { participate } = yield call(
      fetchLeaderboardParticipationStatus,
      action.seriesId
    );
    yield put(
      getLeaderboardParticipationStatusSuccessAction({
        participation: Boolean(participate),
      })
    );
  } catch (error) {
    yield put(getLeaderboardParticipationStatusErrorAction({ error }));
  }
}

export function* watchSetLeaderboardParticipationStatusRequestAction() {
  yield takeLatest(
    actionTypes.EB_SET_LEADERBOARD_PARTICIPATION_STATUS_REQUEST,
    setLeaderboardParticipationStatusSaga
  );
}

function* setLeaderboardParticipationStatusSaga(action) {
  try {
    const {
      participate: { participate: participation },
    } = yield call(
      postLeaderboardParticipationStatus,
      action.seriesId,
      action.participation
    );
    yield put(
      setLeaderboardParticipationStatusSuccessAction({
        participation,
        seriesId: action.seriesId,
      })
    );
  } catch (error) {
    yield put(setLeaderboardParticipationStatusErrorAction({ error }));
  }
}

export function* watchSetLeaderboardParticipationStatusSuccessAction() {
  yield takeLatest(
    actionTypes.EB_SET_LEADERBOARD_PARTICIPATION_STATUS_SUCCESS,
    reloadLeaderboardAfterPaticipationChangeSuccessSaga
  );
}

function* reloadLeaderboardAfterPaticipationChangeSuccessSaga(action) {
  yield put(getLeaderboardRequestAction({ seriesId: action.seriesId }));
}

export function* watchGetLeaderboardRequestAction() {
  yield takeLatest(actionTypes.EB_GET_LEADERBOARD_REQUEST, getLeaderboardSaga);
}

function* getLeaderboardSaga(action) {
  try {
    const { leaderboard } = yield call(fetchLeaderboard, action.seriesId);
    const sortedLeaderboard = leaderboard.sort(
      ({ score: scoreA }, { score: scoreB }) => scoreB - scoreA
    );
    const userNickname = yield select(nickSelector);
    const { userRank, userScore } = findUserInLeaderboard(
      userNickname,
      sortedLeaderboard
    );
    yield put(
      getLeaderboardSuccessAction({
        leaderboard: sortedLeaderboard.splice(0, 20),
        userScore,
        userRank,
      })
    );
  } catch (error) {
    yield put(getLeaderboardErrorAction({ error }));
  }
}
