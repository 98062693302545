import * as React from 'react';
import { Component } from 'react';
import { View, StyleSheet, Animated, Easing } from 'react-native';
import * as PropTypes from 'prop-types';

import * as propTypes from '../../constants/prop-types';
import MediumRing1 from '../../assets/animations/fireworks_rings/fireworks_ring_medium_1.webp';
import MediumRing2 from '../../assets/animations/fireworks_rings/fireworks_ring_medium_2.webp';
import MediumRing3 from '../../assets/animations/fireworks_rings/fireworks_ring_medium_3.webp';
import MediumRing4 from '../../assets/animations/fireworks_rings/fireworks_ring_medium_4.webp';
import LargeRing1 from '../../assets/animations/fireworks_rings/fireworks_ring_large_1.webp';
import LargeRing2 from '../../assets/animations/fireworks_rings/fireworks_ring_large_2.webp';
import LargeRing3 from '../../assets/animations/fireworks_rings/fireworks_ring_large_3.webp';
import LargeRing4 from '../../assets/animations/fireworks_rings/fireworks_ring_large_4.webp';

const ROTATION_DURATION = 10000;
const OPACITY_BEGIN_DELAY = 200;
const OPACITY_STAY_DELAY = 200;
const OPACITY_IN_DURATION = 400;
const OPACITY_OUT_DURATION = 400;

class EbFireworksAnimation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rotationAnim: new Animated.Value(0),
      opacityAnim1: new Animated.Value(0),
      opacityAnim2: new Animated.Value(0),
      opacityAnim3: new Animated.Value(0),
      opacityAnim4: new Animated.Value(0),
    };
  }

  componentDidMount() {
    const {
      rotationAnim,
      opacityAnim1,
      opacityAnim2,
      opacityAnim3,
      opacityAnim4,
    } = this.state;
    const { delay: initialDelay } = this.props;
    Animated.loop(
      Animated.timing(rotationAnim, {
        toValue: 1,
        duration: ROTATION_DURATION,
        easing: Easing.linear,
        useNativeDriver: false,
      })
    ).start();

    Animated.loop(
      Animated.sequence([
        Animated.delay(initialDelay),
        Animated.parallel(
          [opacityAnim1, opacityAnim2, opacityAnim3, opacityAnim4].map(
            (anim, index) =>
              Animated.sequence([
                Animated.delay(index * OPACITY_BEGIN_DELAY),
                Animated.timing(anim, {
                  toValue: 1,
                  duration: OPACITY_IN_DURATION,
                  easing: Easing.in(Easing.quad),
                  useNativeDriver: false,
                }),
                Animated.delay(OPACITY_STAY_DELAY),
                Animated.timing(anim, {
                  toValue: 0,
                  duration: OPACITY_OUT_DURATION,
                  easing: Easing.out(Easing.quad),
                  useNativeDriver: false,
                }),
              ])
          )
        ),
      ])
    ).start();
  }

  render() {
    const {
      rotationAnim,
      opacityAnim1,
      opacityAnim2,
      opacityAnim3,
      opacityAnim4,
    } = this.state;
    const opacityAnims = [
      opacityAnim1,
      opacityAnim2,
      opacityAnim3,
      opacityAnim4,
    ];
    const { mode, style } = this.props;
    const cwRotStr = rotationAnim.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg'],
    });
    const ccwRotStr = rotationAnim.interpolate({
      inputRange: [0, 1],
      outputRange: ['360deg', '0deg'],
    });
    const images = ModeImages[mode];
    const imageAnimData = images.map((image, index) => ({
      key: `anim-${index}`,
      source: image,
      imageStyle: [
        styles.ring,
        {
          width: ringSizes[mode][index],
          height: ringSizes[mode][index],
          transform: [{ rotate: index % 2 ? cwRotStr : ccwRotStr }],
          opacity: opacityAnims[index],
        },
      ],
    }));
    return (
      <View pointerEvents="none" style={[style || {}, styles.container]}>
        {imageAnimData.map(({ key, source, imageStyle }) => (
          <Animated.Image key={key} source={source} style={imageStyle} />
        ))}
      </View>
    );
  }
}

EbFireworksAnimation.mode = {
  LARGE: 'LARGE',
  MEDIUM: 'MEDIUM',
};

const ringSizes = {
  [EbFireworksAnimation.mode.LARGE]: [176, 258, 311, 411],
  [EbFireworksAnimation.mode.MEDIUM]: [150, 200, 228, 312],
};

const ModeImages = {
  [EbFireworksAnimation.mode.MEDIUM]: [
    MediumRing1,
    MediumRing2,
    MediumRing3,
    MediumRing4,
  ],
  [EbFireworksAnimation.mode.LARGE]: [
    LargeRing1,
    LargeRing2,
    LargeRing3,
    LargeRing4,
  ],
};

EbFireworksAnimation.defaultProps = {
  style: { position: 'absolute' },
  delay: 0,
};

EbFireworksAnimation.propTypes = {
  mode: PropTypes.oneOf([
    EbFireworksAnimation.mode.LARGE,
    EbFireworksAnimation.mode.MEDIUM,
  ]).isRequired,
  style: propTypes.style,
  delay: PropTypes.number,
};

const styles = StyleSheet.create({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  ring: {
    flex: 1,
    resizeMode: 'contain',
    position: 'absolute',
    width: 100,
    height: 100,
  },
});

export default EbFireworksAnimation;
